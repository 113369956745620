import React from "react";
import { Row, Col, Card } from "react-bootstrap";
import { useEffect } from "react";
import { connect } from "react-redux";
import ApexCharts from "react-apexcharts";
import { BsThreeDotsVertical } from "react-icons/bs";
import { fetchPssFailSessionAction } from "../../../../store/actions/insightAction";
import { useTranslation } from "react-i18next";

function SessionGraph(props) {
  const { t } = useTranslation();
  useEffect(() => {
    setTimeout(() => {
      props.getPS();
    }, 1500);
  }, []);

  let psmonthpush = [];
  let psStatusList = [];

  const ps_list = props.total_pf_s && props.total_pf_s?.data && props.total_pf_s?.data;

  // console.log("total", ps_list);
  const ps_month = ps_list && ps_list.map((m) => {
    if (m.number === 1) {
      psmonthpush.push("Jan");
      psStatusList.push(m.status);
    } else if (m.number === 2) {
      psmonthpush.push("Feb");
      psStatusList.push(m.status);
    } else if (m.number === 3) {
      psmonthpush.push("March");
      psStatusList.push(m.status);
    } else if (m.number === 4) {
      psmonthpush.push("April");
      psStatusList.push(m.status);
    } else if (m.number === 5) {
      psmonthpush.push("May");
      psStatusList.push(m.status);
    } else if (m.number === 6) {
      psmonthpush.push("Jun");
      psStatusList.push(m.status);
    } else if (m.number === 7) {
      psmonthpush.push("July");
      psStatusList.push(m.status);
    } else if (m.number === 8) {
      psmonthpush.push("Aug");
      psStatusList.push(m.status);
    } else if (m.number === 9) {
      psmonthpush.push("Sep");
      psStatusList.push(m.status);
    } else if (m.number === 10) {
      psmonthpush.push("Oct");
      psStatusList.push(m.status);
    } else if (m.number === 11) {
      psmonthpush.push("Nov");
      psStatusList.push(m.status);
    } else if (m.number === 12) {
      psmonthpush.push("Dec");
      psStatusList.push(m.status);
    }
  });

  const uniqueMonthSet = new Set(psmonthpush);
  const uniqueMonthArray = Array.from(uniqueMonthSet);
  // console.log("uniqueArray", uniqueMonthArray.sort());

  const options = {
    xaxis: {
      categories: uniqueMonthArray,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        // endingShape: "rounded",
        borderRadius: 5,
      },
    },
    legend: {
      position: "top",
      offsetY: 0,
      showForSingleSeries: true,
    },
    dataLabels: {
      enabled: false,
    },
    colors: ["#007bff", "#00e396", "#feb019", "#ff4560", "#7655e4", "#002C4B"],
  };


  const series = [
    {
      name: "Completed",
      data: props.total_pf_s && props.total_pf_s.data?.filter((m) => m.status === "Completed")?.map((item) => item.total),

    },
    {
      name: "Failed",
      data: props.total_pf_s && props.total_pf_s.data?.filter((m) => m.status === "Failed")?.map((item) => item.total),
    },
    {
      name: "Faulted",
      data: props.total_pf_s && props.total_pf_s.data?.filter((m) => m.status === "Activated")?.map((item) => item.total),
    },
    {
      name: "Rejected",
      data: props.total_pf_s && props.total_pf_s.data?.filter((m) => m.status === "Rejected")?.map((item) => item.total),
    },
    {
      name: "SuspendedEVSE",
      data: props.total_pf_s && props.total_pf_s.data?.filter((m) => m.status === "SuspendedEVSE")?.map((item) => item.total),
    },
    {
      name: "Unavailable",
      data: props.total_pf_s && props.total_pf_s.data?.filter((m) => m.status === "Unavailable")?.map((item) => item.total),
    },
  ];
  // console.log(props.total_pf_s);
  return (
    <div>
      <Card className="statuscard">
        <Card.Header>
          <Row>
            <Col xs={12} md={4}>
              <div className="text-left">{t("Session")}</div>
            </Col>
            <Col>
              <Col className="text-right">
                <div className="btn-group dropleft">
                  <button
                    className="btn btn-md"
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {
                      <i className="" title="Graph">
                        <BsThreeDotsVertical />
                      </i>
                    }
                  </button>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <a className="dropdown-item" href="./session_status_report">
                      {t("Session Status Report")}
                    </a>
                  </div>
                </div>
              </Col>
            </Col>
          </Row>
        </Card.Header>
        <div>
          {props.loading ? <h3 style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "355px",
          }}>Loading data...</h3> :
            props.total_pf_s.data !== undefined ? (

              <ApexCharts
                options={options}
                series={series}
                type="bar"
                height={355}

              />) : (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "355px",
                }}
              >
                <h3>{t("No data found")}</h3>
              </div>
            )}
        </div>
      </Card>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    total_pf_s: state.insight.session_p_f_session,
    loading: state.insight.isLoading,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getPS: () => dispatch(fetchPssFailSessionAction()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SessionGraph);
