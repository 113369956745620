import React from "react";
import { Row, Col, Card } from "react-bootstrap";
import { useEffect } from "react";
import { connect } from "react-redux";
import ApexCharts from "react-apexcharts";
import { BsThreeDotsVertical } from "react-icons/bs";
import { fetchSessionTypeAction } from "../../../../store/actions/insightAction";
import { useTranslation } from "react-i18next";

function SessionTypeGraph(props) {
  const { t } = useTranslation();
  useEffect(() => {
    setTimeout(() => {
      props.getST();
    }, 1500);
  }, []);

  let oList = [],
    aList = [],
    rList = [];

  const lbs = props.total_s_t && props.total_s_t.data?.map((m) => {
    if (m.type === "App") {
      aList.push(m.total);
    }
    if (m.type === "RFID") {
      rList.push(m.total);
    }
    if (m.type === "") {
      oList.push(m.total);
    }
  });

  // console.log("total", props.total_s_t)
  const data = {
    options: {
      labels: ['APP', 'RFID', 'Others'],

      fill: {
        type: "gradient",
        gradient: {
          shade: "light",
          type: "horizontal",
          shadeIntensity: 0.5,
          gradientToColors: ["#5559B2", "#0BCF6B"],
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100],
        },
      },
      tooltip: {
        y: {
          formatter: function (value) {
            return value.toFixed(0);
          },
        },
      },
      dataLabels: {
        enabled: false, // Set this to false to hide data labels
      },
      legend: {
        position: "top",
      },
    },
    series: props.total_s_t && Array.isArray(props.total_s_t.data) ? props.total_s_t.data.map((item) => item.total) : [],
  };

  return (
    <div>
      <Card className="statuscard" style={{ "height": "445px" }}>
        <Card.Header>
          <Row>
            <Col xs={12} md={4}>
              <div className="text-left">{t("Session Type")}</div>
            </Col>
            <Col>
              <Col className="text-right">
                <div className="btn-group dropleft">
                  <button
                    className="btn btn-md"
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {
                      <i className="" title="Graph">
                        <BsThreeDotsVertical />
                      </i>
                    }
                  </button>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <a className="dropdown-item" href="./session_type_report">
                      {t("Session Type Reports")}
                    </a>
                  </div>
                </div>
              </Col>
            </Col>
          </Row>
        </Card.Header>
        <div>
          <ApexCharts
            options={data.options}
            series={data.series}
            type="donut"
            height={350}
          />
        </div>
      </Card>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    total_s_t: state.insight.session_t_t,
    loading: state.insight.isLoading,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getST: () => dispatch(fetchSessionTypeAction()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SessionTypeGraph);
